const NotificationData = (state = { notificationDetail_detail: [] }, action) => {
    switch (action.type) {
      case "NOTIFICATIONDETAIL":
        return {
            notificationDetail_detail: action.payload
        }
      default:
        return state;
    }
  }
  
  export default NotificationData;