import React from "react";
import Header from "./Header";
import { Link, NavLink } from "react-router-dom";
 import Footer from "./Footer";
// import StudentProfilePic from '../../assets/img/student-profile-pic.jpg'
import logo from '../../assets/image/halal-logo.png'
import { useSelector } from "react-redux";
import rootReducer from "../../redux/rootReducer";

const loginAdmin = JSON.parse(localStorage.getItem("loginAdmin"));

const SidebarLayout = (props) => {

  const Admindata = useSelector(rootReducer=>rootReducer.AdminData)

  const location = window.location
  const restaurant = location.pathname.match(/^\/restaurant/) ? "active" : "";
  const product = location.pathname.match(/^\/product/) ? "active" : "";
  const menu = location.pathname.match(/^\/menu/) ? "active" : "";
  const rating = location.pathname.match(/^\/rating/) ? "active" : "";
  const notification = location.pathname.match(/^\/notification/) ? "active" : "";
  const branch = location.pathname.match(/^\/branch/) ? "active" : "";
  const ingredient = location.pathname.match(/^\/ingredient/) ? "active" : "";
  
  
  return (<>
  
 
    {/* <SidebarNav/>*/}
    <Header />
<div className="Adjust">
  <div className="Sidebar">
      <aside className="navbar-aside navbar-aside_custom" id="offcanvas_aside">
        <div className="aside-top border-bottom">
          <Link to="/users" className="brand-wrap">
            <div class="admin_logo">
              <img src={logo} class="img-fluid login_logo" alt="#" />
            </div>
          </Link>
        </div>
        <nav>
          <ul className="menu-aside">
          <li className="menu-item">
              <NavLink
                activeclassname="active "
                className={`menu-link`}
                to="/dashboard"
                exact={true}>
                <i className="fa fa-tachometer"></i>
                <span className="text">Dashboard</span>
              </NavLink>
            </li>

           
            <li className="menu-item">
              <NavLink
                activeclassname="active "
                className={`menu-link ${menu}`}
                to="/menu"
                exact={true}>
                <i className="fa fa-bars"></i>
                <span className="text">Manage Menus</span>
              </NavLink>
            </li>
             <li className="menu-item">
              <NavLink
                activeclassname="active "
                className={`menu-link ${product}`}
                to="/product"
                exact={true}>
                <i className="fa fa-shopping-cart"></i>
                <span className="text">Manage Products</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeclassname="active "
                className={`menu-link ${ingredient}`}
                to="/ingredient"
                exact={true}>
                <i className="fas fa-cheese"></i>
                <span className="text">Manage Ingredients</span>
              </NavLink>
            </li>
            
             <li className="menu-item">
              <NavLink
                activeclassname="active "
                className={`menu-link ${restaurant}`}
                to="/restaurant"
                exact={true}>
                <i className="fa fa-utensils"></i>
                <span className="text">Manage Restaurant</span>
              </NavLink>
            </li>

            {Admindata.admin_detail.isAdmin  ? <li className="menu-item">
            <NavLink
              activeclassname="active "
              className={`menu-link ${branch}`}
              to="/branch"
              exact={true}>
              <i className="fa fa-code-branch"></i>
              <span className="text">Manage Branches</span>
            </NavLink>
          </li>: loginAdmin?.isAdmin == 1 && <li className="menu-item">
            <NavLink
              activeclassname="active "
              className={`menu-link ${branch}`}
              to="/branch"
              exact={true}>
              <i className="fa fa-code-branch"></i>
              <span className="text">Manage Branches</span>
            </NavLink>
          </li> }
            {/* <li className="menu-item">
              <NavLink
                activeclassname="active "
                className={`menu-link ${manager}`}
                to="/manager"
                exact={true}>
                <i class="fa fa-users" aria-hidden="true"></i>
                <span className="text">Manage Managers</span>
              </NavLink>
            </li>
             */}
            <li className="menu-item">
              <NavLink
                activeclassname="active "
                className={`menu-link ${rating}`}
                to="/rating"
                exact={true}>
                <i className="fa fa-star"></i>
                <span className="text">Ratings & Reviews </span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeclassname="active "
                className={`menu-link ${notification}`}
                to="/notification"
                exact={true}>
                <i className="fa fa-bell"></i>
                <span className="text">Notifications</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeclassname="active "
                className={`menu-link`}
                to="/ChangePassword"
                exact={true}>
                <i class="fa fa-key" aria-hidden="true"></i>
                <span className="text">Change Password</span>
              </NavLink>
            </li>
            
          </ul>
          <br />
          <br />
        </nav>
      </aside>
    </div>
    
    <div className="Content">

<div className="child">
  {props.children} 
  </div>
  </div>
</div>
    <Footer />
  </>);
}

export default SidebarLayout;