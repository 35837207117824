
import AdminData from "./Reducer/AdminDetail";
import NotificationData from "./Reducer/NotificationDetail";
import { combineReducers } from "redux";

const rootReducer = combineReducers({

  AdminData,
  NotificationData
});

export default rootReducer;