import React, { Suspense, Fragment, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Authenticate from "./component/Gurd/Authenticate"
import LoadScreen from './component/loaderScreen';
import SidebarLayout from './layouts/SidebarLayout'
import Guest from './component/Gurd/Guest';
import Protect from './component/Gurd/Protect';
const loginAdmin = JSON.parse(localStorage.getItem("loginAdmin"));


export function RenderRout() {
  
    return (
      <>
        <Router>
          <Suspense fallback={<div><LoadScreen/></div>}
          >
              <Routes>
              {routes?.map((route, i) => {
                const Guard = route?.guard || Fragment;
                const Protect = route?.protect || Fragment;
                const Layout = route?.layout || Fragment;
                const Component = route?.element;
                return (
                  <Route
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    element={
                      <Guard>
                        <Protect>
                        <Layout >
                          <Component />
                          </Layout>
                          </Protect>
                      </Guard>
                    }
                  />
                );
              })}
            </Routes>
          </Suspense>
        </Router>
         </>
    )
  
  }

  const routes = [
  
    {
      
      exact: true,
      guard: Guest,
      path: '/forgotPassword',
      element: lazy(() => import('./component/Admin/ForgotPassword'))
    },
  
  
    {
    
      exact: true,
      guard: Guest,
      path: '/resetPassword/:id/:token',
      element: lazy(() => import('./component/Admin/ResetPassword'))
    },
    
    {
      exact: true,
      guard: Guest,
      path: '/register',
      element: lazy(() => import('./component/Admin/Register'))
    },
    {
      exact: true,
      guard: Guest,
      path: '/',
      element: lazy(() => import('./component/Admin/Login'))
    },
    {
      exact: true,
      path: '/cms',
      element: lazy(() => import('./component/Cms'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/ChangePassword',
      element: lazy(() => import('./component/Admin/ChangePassword'))
    },

    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/restaurant',
      element: lazy(() => import('./component/Restaurant/RestaurantEdit'))
    },
  
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/dashboard',
      element: lazy(() => import('./component/Dashboard'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/product',
      element: lazy(() => import('./component/Product'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/product/:id',
      element: lazy(() => import('./component/Product'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/product-view',
      element: lazy(() => import('./component/Product/ProductView'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/product-edit',
      element: lazy(() => import('./component/Product/ProductEdit'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/product-rating',
      element: lazy(() => import('./component/Product/Rating'))
    },
    
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/product-create',
      element: lazy(() => import('./component/Product/ProductCreate'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/notification',
      element: lazy(() => import('./component/Notification'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/notification-view',
      element: lazy(() => import('./component/Notification/NotificationView'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/rating',
      element: lazy(() => import('./component/Rating'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/menu',
      element: lazy(() => import('./component/Menu'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/menu-view',
      element: lazy(() => import('./component/Menu/MenuView'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/menu-edit',
      element: lazy(() => import('./component/Menu/MenuEdit'))
    },
    {
      layout: SidebarLayout,
      guard: Authenticate,
      exact: true,
      path: '/menu-create',
      element: lazy(() => import('./component/Menu/MenuCreate'))
    },
   //Branch
    {
    layout: SidebarLayout,
    guard: Authenticate,
    protect: Protect,
    exact: true,
    path: '/branch',
    element: lazy(() => import('./component/Branch'))
  },
  {
    layout: SidebarLayout,
    guard: Authenticate,
    protect: Protect,
    exact: true,
    path: '/branch-create',
    element: lazy(() => import('./component/Branch/BranchCreate'))
  },

  {
    layout: SidebarLayout,
    guard: Authenticate,
    protect: Protect,
    exact: true,
    path: '/branch-edit',
    element: lazy(() => import('./component/Branch/BranchEdit'))
  },
 {
    layout: SidebarLayout,
    guard: Authenticate,
    protect: Protect,
    exact: true,
    path: '/branch-view',
    element: lazy(() => import('./component/Branch/BranchView'))
  },
  {
    layout: SidebarLayout,
    guard: Authenticate,
    exact: true,
    path: '/manager',
    element: lazy(() => import('./component/Manager'))
  },
  {
    layout: SidebarLayout,
    guard: Authenticate,
    exact: true,
    path: '/manager-create',
    element: lazy(() => import('./component/Manager/ManagerCreate'))
  },
  {
    layout: SidebarLayout,
    guard: Authenticate,
    exact: true,
    path: '/ingredient',
    element: lazy(() => import('./component/Ingredients'))
  },
  {
    layout: SidebarLayout,
    guard: Authenticate,
    exact: true,
    path: '/ingredient-subcategory',
    element: lazy(() => import('./component/Ingredients/subCategoryList'))
  },
  {
    layout: SidebarLayout,
    guard: Authenticate,
    exact: true,
    path: '/ingredient-create',
    element: lazy(() => import('./component/Ingredients/IngredientsCreate'))
  },
  {
    layout: SidebarLayout,
    guard: Authenticate,
    exact: true,
    path: '/ingredient-subcategory-create',
    element: lazy(() => import('./component/Ingredients/AddSubcategory'))
  },
  {
    layout: SidebarLayout,
    guard: Authenticate,
    exact: true,
    path: '/ingredient-edit',
    element: lazy(() => import('./component/Ingredients/IngredientsEdit'))
  },
  
]
