import React from "react";
import { Link } from "react-router-dom";
export default function Footer() {
  const cmsList=  JSON.parse(localStorage.getItem("contents"));

    return (
        <>
 <footer>
    <ul className="footer_li">
      {
        (cmsList != undefined && cmsList.length > 0) && cmsList.map((item,key)=>(
          <li key={key}><Link to={`/cms?id=${item?._id}`} target="_blank">{item?.title}</Link></li>
        ))
      }
     
    </ul>
  </footer>
        </ >
    )
}