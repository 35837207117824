const AdminData = (state = { admin_detail: {} }, action) => {
    switch (action.type) {
      case "ADMINDETAIL":
        return {
          admin_detail: action.payload
        }
      default:
        return state;
    }
  }
  
  export default AdminData;