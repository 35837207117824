import React from 'react'
import Badge from '@mui/material/Badge';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Menu from '@mui/material/Menu';
import moment from 'moment';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

export default function Notify() {
  const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlenavigate = (id) => {
    setAnchorEl(null);
    navigate('/notification-view?id=' + id)
  };
  
    const notification=  useSelector((state) => state.NotificationData.notificationDetail_detail)
    

  return (
    <>
     <Badge badgeContent={notification?notification?.length:'0'} color="primary" onClick={handleClick} style={{ cursor:"pointer" }}>
     <Link className="nav-link btn-icon notification_icon" to="#"/>
     <i className="fas fa-bell"></i>
    </Badge>
    {notification !=undefined && notification?.length > 0 && 
    <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className='custom-nitification-drop'
       
      >
 {notification !=undefined && notification.map((option,i) => (
<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} className='custom-nitification-list' key={i} onClick={(()=>handlenavigate(option?._id))} style={{ cursor:"pointer" }}>
      <ListItem alignItems="flex-start" className='custom-nitification-listitem'>
        <ListItemAvatar className='custom-nitification-ListItemAvatar'>
        <i class="fa-solid fa-bell"></i>
        </ListItemAvatar>
        <ListItemText  className='custom-nitification-ListItemText'
          primary={option?.title}
          secondary={
            <React.Fragment>
              {option?.message}
            </React.Fragment>
          }
        />
         
      </ListItem>
      <p class="ntime">{moment(option?.createdAt).format("YYYY-MM-DD HH:mm:ss")}</p>
      <Divider variant="inset" component="li" className='custom-nitification-Divider'/>
    </List>
          ))}
       
      </Menu>
}
    </>
  )
}
