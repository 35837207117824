import { RenderRout } from "./Router";
import "./App.css";
import './icofont.min.css'
import "./responsive.css";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <div>
    <RenderRout/>
    
    </div>
  );
}

export default App;
