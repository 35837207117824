import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Protect = ({ children }) => {
  const navigate = useNavigate();
  const loginAdmin = JSON.parse(localStorage.getItem("loginAdmin"));
  const Admindata = useSelector(rootReducer=>rootReducer.AdminData)
  
useEffect(() => {

 if(Admindata.admin_detail.isAdmin && Admindata?.admin_detail?.isAdmin != 1 ){
    navigate('/')
 }
 else if(loginAdmin?.isAdmin != 1) {
    navigate('/')
 }
}, [loginAdmin]);

  return <>{children}</>;
};
export default Protect;
